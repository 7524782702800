import { useEffect } from "react";
import { useParams, useRouter } from "next/navigation";
import { twMerge } from "tailwind-merge";
import { WelcomeBanner } from "./WelcomeBanner";
import HeaderBannerLayout from "components/common/v2/HeaderBannerLayout";
import Loader from "components/common/Loader";
import { TaskProgressHeader } from "./TaskProgressHeader";
import { ONBOARDING_CATEGORY_LIST } from "./tasks";
import { URI_GET_STARTED } from "constants/urls";
import { DismissGuideButtons } from "./DismissGuideButtons";
import { OnboardingCategoryList } from "./OnboardingCategoryList";
import { OnboardingTaskList } from "./OnboardingTaskList";
import { useOnboardingTasks } from "./useOnboardingTasks";
import { launchFireworks } from "utils/fire-the-confetti";
import { useGetStartedEnabled } from "hooks/useGetStartedEnabled";
import Layout from "components/common/v2/Layout";

// A browser local storage key for a flag indicating that the user has completed onboarding and hit the main page.
// NOTE: Currently only using this to prevent repeat fireworks, but can technically use this to pop a modal or
// show an animation the first time a user hits the main Get Started page after finishing all tasks.
const GET_STARTED_COMPLETED_LS_KEY = "get-started-completed";

/** The primary Get Started (onboarding/tutorial) page */
export const GetStarted = (): JSX.Element => {
    const router = useRouter();
    const params = useParams();
    const categoryId = params?.categoryId?.[0];

    const { getStartedHidden: hideDismissButtons } = useGetStartedEnabled();

    const openCategory = ONBOARDING_CATEGORY_LIST.find(({ id }) => id === categoryId);

    useEffect(() => {
        if (categoryId && !openCategory) router.replace(URI_GET_STARTED);
    }, [categoryId, openCategory]);

    const {
        completedTasks = [],
        completedTaskCount,
        allTasksComplete,
        isLoadingTasks,
        markAsDone
    } = useOnboardingTasks();

    const doneAndOnHome = allTasksComplete && !openCategory;

    useEffect(() => {
        if (doneAndOnHome && !localStorage.getItem(GET_STARTED_COMPLETED_LS_KEY)) {
            launchFireworks(5);
            localStorage.setItem(GET_STARTED_COMPLETED_LS_KEY, "yes");
        }
    }, [doneAndOnHome]);

    return (
        <Layout className="tablet:py-6">
            <div className="flex flex-col w-full h-fit items-center pb-6">
                <WelcomeBanner />
                <div
                    className={twMerge(
                        // Note: Negative margin places this card a nice distance from the "Welcome to FORA" text.
                        // If we adjust the height of the Welcome Banner, we should adjust this margin, too
                        "flex flex-col rounded-xl md:shadow-lg bg-white w-full max-w-[720px] z-10 -mt-16",
                        !openCategory ? "divide-y divide-lightGreyBorder" : "md:p-6 gap-y-6"
                    )}
                >
                    {isLoadingTasks ? (
                        <Loader heightClassName="h-[50vh]" />
                    ) : (
                        <>
                            <TaskProgressHeader
                                taskId={categoryId}
                                tasksComplete={completedTaskCount}
                            />
                            {!openCategory ? (
                                <OnboardingCategoryList completedTasks={completedTasks} />
                            ) : (
                                <OnboardingTaskList
                                    category={openCategory}
                                    completedTasks={completedTasks}
                                    markAsDone={async (taskId, metadata) => {
                                        await markAsDone(taskId, metadata);
                                    }}
                                />
                            )}
                        </>
                    )}
                </div>
                {!openCategory && !hideDismissButtons && (
                    <DismissGuideButtons allTasksComplete={allTasksComplete} />
                )}
            </div>
        </Layout>
    );
};
